body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer {
  background-color: #3a3a3a;
  color: white;
  font-size: 0.9em;
}

.footer-header {
  padding-bottom: 5px;
  border-bottom: 3px solid #f9af00;
}

.icch-navbar {
  background-color: #f9af00;
}

.copyright-row {
  padding-top: 5px;
  padding-bottom: 10px;
  border-top: 1px solid #f9af00;
}

.contact-card {
  margin-bottom: 15px;
}

.contact-card-header {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 5px;
  color: aqua;
}

.speaker-card-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

#Carousel {
  display: none;
}

.title-header {
  font-size: 120px;
}

.profile-image-container {
  width: 140px;
  margin-bottom: 10px;
}

.contrast-link {
  color: #f9af00;
}

.speaker-image {
  width: 128px;
  height: 128px;
  border: 5px solid #ccc;
}

.detail-speaker-card {
  background-color: #f8f9fa;
  padding: 25px;
  margin-bottom: 25px;
}

.countdown-container {
  font-size: 20px;
}
